// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { environment_data } from "src/assets/logos/environment.data";
export const environment = {
  production: false,
  //mqttbroker: environment_data.mqttbroker,
  // mqttbroker: 'subasta-mqtt.apvigo.es', // APVIGO PROD
  mqttbroker: "mqtt.saecdata.net", // DEVTEST
  //mqttbroker: "192.168.10.87", // desarrollo 87
  // mqttbroker: "celeiro-mqtt.saecdata.net", // celeiro
  // mqttbroker:"mqtt.docpeixe.es", // ACERGA

  //mqttprotocol: environment_data.mqttprotocol,
  //mqttprotocol: "ws", // solo para la 87
   mqttprotocol: "wss",

  //mqttport: environment_data.mqttport,
  //mqttport: 8083, // ACERGA STG
  // mqttport: 8084, //docker DEVTEST DEMOS
   mqttport: 8083, // docker STG APVIGO

  //useSSL: environment_data.useSSL,
  useSSL: true,

  timeout: 3, // sin uso aparente en el proyecto

  //password: environment_data.password,
  password:"subasta.2020",
  // password: "12345678",

  //siteKey: environment_data.siteKey,
  siteKey: '6Lddct0kAAAAABBjuCwEuR0f_OW5C2UOzKOpOH8s',
  // siteKey: '6LfubsUlAAAAAD_H_s-v4rlrXqE9wudIB-0mmdZ2',

  //APIURL: environment_data.APIURL,
  // APIURL: "https://subasta-login.saecdata.net", // DEVTEST DEMOS
  // APIURL: "https://acerga-api-subasta.saecdata.net", // ACERGA STG
  // APIURL:"https://celeiro-api.saecdata.net",
  //APIURL: "https://apisubasta-apvigo.saecdata.net", // DEV-TEST APVIGO
  //APIURL: "http://192.168.33.180:5001", // PC ANDRES
  // APIURL: "http://192.168.33.48:5001", // PC PABLO
  // APIURL: "http://192.168.10.87:5001", // 87
  APIURL: "https://stg-api-subasta.docpeixe.es", // ACERGA STG
  version: "0.3.40"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
